import Counts from "./home/Counts";
import Featured from "./home/Featured";
import HomeSearch from "./home/HomeSearch";
import HowToBuy from "./home/HowToBuy";
import NewProducts from "./home/NewestProducts";
import Services from "./home/Services";
import JoinUs from "./home/JoinUs";
import Subscribe from "./home/Subscribe";


const Home = () => {
  return (
    <>
      <HomeSearch />
      <div  className="max-w-[1140px] m-auto p-[50px]">
        <div className='text-3xl text-center flex flex-col justify-center items-center text-center'>
          <img className='w-[50px] m-2' src="/warning.png" alt="" /> Imporant Notice
        </div>
        <br />
        <div className='text-2xl w-[90%] m-auto text-error'>
          We are currently in the development stage and are offering a beta version of our website. Our team is actively working on adding new features and services to enhance the user experience. Once the beta testing phase is complete, we will share more information about the project and our goals with the public.
          <br />
          <br />
          ***We kindly request that you do not hold more than 100 PI in your accounts. This will help us to maintain the security and stability of our system. Thank you for your cooperation.
        </div>
      </div>
      <Featured />
      <NewProducts />
      <Services />
      <Counts />
      <HowToBuy />
      <JoinUs />
      <Subscribe />
    </>
  );
};

export default Home;