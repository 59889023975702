import { useState } from 'react';
import Subscribe from './home/Subscribe'
import JoinUs from './home/JoinUs';

const AboutUs = () => {

    return (
        <>
            <section className={`aboutus text-gray-700 body-font relative min-h-[550px] max-w-[1160px] m-auto pb-[50px]`}>
                <h1 className='text-center text-4xl p-5 flex flex-col justify-center items-center'> <img className='w-[50px] m-2'  src="/third-party.png" alt="" />About Us</h1>
                <div className="about-us text-2xl w-[90%] m-auto">
                    Welcome to Escrowfy, your trusted partner in secure and seamless transactions. We understand the importance of trust when it comes to handling your valuable assets, and that's why we've built a platform that prioritizes security, transparency, and efficiency.
                </div>
                <br /><br />
                <div className='text-3xl flex flex-col justify-center items-center text-center '>
                   <img className='w-[50px]' src="pi.png" alt="" /> PI Coin for Escrow Payment
                </div>
                <br />
                <div className='text-2xl w-[90%] m-auto'>
                    We utilize PI Coin for our escrow services, providing you with an additional layer of security and efficiency in your transactions.
                </div>
                <br />
                <div className='text-3xl text-center flex flex-col justify-center items-center text-center'>
                <img className='w-[50px] m-2'  src="/warning.png" alt="" /> Imporant Notice
                </div>
                <br />
                <div className='text-2xl w-[90%] m-auto text-error'>
                    We are currently in the development stage and are offering a beta version of our website. Our team is actively working on adding new features and services to enhance the user experience. Once the beta testing phase is complete, we will share more information about the project and our goals with the public.
                    <br />
                    <br />
                    ***We kindly request that you do not hold more than 100 PI in your accounts. This will help us to maintain the security and stability of our system. Thank you for your cooperation.
                </div>
                {/* Our Mission
                At [EscrowCompanyName], our mission is to revolutionize the way transactions are conducted online. We aim to provide a safe and reliable environment where buyers and sellers can confidently engage in business, knowing that their interests are protected at every step.

                Who We Are
                We are a team of dedicated professionals with extensive experience in the financial and technology sectors. Our experts have come together to create a platform that combines cutting-edge technology with industry-best practices to offer you the highest level of security and convenience.

                What Sets Us Apart
                Security First
                Security is our top priority. We employ state-of-the-art encryption protocols and multi-layered authentication processes to ensure that your funds and sensitive information are always protected.

                Transparent Transactions
                We believe in complete transparency. Every transaction on our platform is recorded, and parties involved have access to real-time updates. This ensures that all parties are on the same page throughout the process.

                Dedicated Support
                Our customer support team is available around the clock to assist you with any inquiries or concerns you may have. We're committed to providing you with the best possible experience on our platform.

                Tailored Solutions
                Whether you're a small business owner, a freelancer, or a large enterprise, we have tailored solutions to meet your specific needs. Our flexible escrow services can be customized to suit a wide range of industries and transaction types.

                Global Reach
                We operate on a global scale, allowing buyers and sellers from different parts of the world to transact with ease. Our platform supports multiple currencies and languages, making international transactions as simple as domestic ones.

                Our Commitment
                We are committed to fostering trust and security in online transactions. With [EscrowCompanyName], you can be confident that your transactions are in safe hands. Join us in revolutionizing the way business is done online.

                If you have any questions or would like to get in touch with us, please don't hesitate to contact us. We look forward to serving you. */}

            </section>
            <JoinUs />
            <Subscribe />
        </>
    );
};

export default AboutUs;