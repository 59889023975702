import { memo, useEffect, useState } from 'react';
import styles from './styles/Deposit.module.css'
import { AxiosInstanceJWT, convertDatetimeToDate, postJWT } from '../AxiosHeaders.js';
import LoadingArea from '../GlobalTemplates/LoadingArea';
import { EmptyMessage } from '../home/templates/Error';
import { FlaotingErrorCustom } from '../GlobalTemplates/FloatingErrorCustom'


import { useAuth } from '../../AuthContext';

const Deposit = () => {
    const [piready, setPiReady] = useState(false)
    const [depositApproved, setDepositApproved] = useState(false)
    const [piUID, setPiUID] = useState("")
    const axiosInstanceJWT = AxiosInstanceJWT()
    const { logout } = useAuth();

    const [amount, setAmount] = useState(0)
    const [depositError, setDepositError] = useState("")
    const [err, setErr] = useState(false)
    const [clicked, setClicked] = useState(false)

    const [depositData, setDepositData] = useState([])
    const [depositSuccess, setDepositSuccess] = useState(false)
    const [piError, setPiError] = useState(false)

    const handleAmount = (event, amount) => {
        setAmount(amount)
    }
    function onIncompletePaymentFound (payment) {
        const data = {
            "payment_id": payment.identifier,
        }
        postJWT('/api/update-deposit/', data)
    }


    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://sdk.minepi.com/pi-sdk.js';
        script.async = true;
        script.onload = () => {
            window.Pi.init({ version: "2.0"});

            const scopes = ['payments', 'wallet_address'];


            window.Pi.authenticate(scopes, onIncompletePaymentFound).then(function (auth) {
                setPiReady(true)
                setPiUID(auth.user.uid)
            }).catch(function (error) {
                console.error(error);
            });
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleDeposit = (event) => {
        if(piready===false){
            setDepositError(`Pi is not authenticated.`)
            return;
        }
        setErr(false)
        if (amount < 10 || amount > 5000) {
            setErr(true)
            if (amount < 10) {
                setDepositError(`Ensure the amount is greater than or equal to $10.`)
            } else {
                setDepositError(`Ensure the amount is smaller than or equal to $5000.`)
            }
            return;
        }


        const paymentData = {
            amount: amount,
            memo: "from user to app",
            metadata: { deposit : amount },
            uid: piUID
        };

        const paymentCallbacks = {
            onReadyForServerApproval: function(paymentId) {
                setDepositSuccess(false)
                setPiError(false)
                if(depositApproved){
                    return
                }
                setDepositApproved(true)
                const Amount = parseFloat(amount).toFixed(2)
                const postDeposit = async () => {
                    try {
                        const response = await axiosInstanceJWT.post('/api/deposit/', {
                            amount: Amount,
                            payment_id:paymentId,
                        })
                        return response
                    } catch (error) {
                        throw error
                    }
                }
                const data = postDeposit()
                data.then(data => {
                    if (data.status === 201) {
                        setDepositData(data.data)

                    }
                }).catch(err => {
                    setErr(true)
                    if (err.response) {
                        if (err.response.status === 401) {
                            logout();
                        } else if (err.response.status === 429) {
                            alert("Too many requests.");
                        } else {
                            setDepositError("Unexpected error with status code: ", err.response.status);
                        }
                    } else {
                        setDepositError("No response received from the server.");
                    }
                })
            },
            onReadyForServerCompletion: function(paymentId, txid) {
                setDepositApproved(false)
                setPiError(false)
                if(depositSuccess){
                    return
                }
                setDepositSuccess(true)
                const data = {
                    "payment_id": paymentId,
                }
                postJWT('/api/update-deposit/', data)
                .then(data=>{
                    
                }).catch(err=>{
                    setErr(true)
                    if (err.response) {
                        if (err.response.status === 401) {
                            logout();
                        } else if (err.response.status === 429) {
                            alert("Too many requests.");
                        } else {
                            setDepositError("Failed to complete the deposit.");
                        }
                    } else {
                        setDepositError("No response received from the server.");
                    }
                })
            },
            onCancel: function(paymentId) {
                setDepositSuccess(false)
                setDepositApproved(false)
                if(piError){
                    return
                }
                setPiError(true)
                const data = {
                    "payment_id": paymentId,
                }
                postJWT('/api/cancel-deposit/', data)
                .then(data=>{
                    alert("The deposit was cancelled.")
                }).catch(err=>{
                    setErr(true)
                    if (err.response) {
                        if (err.response.status === 401) {
                            logout();
                        } else if (err.response.status === 429) {
                            alert("Too many requests.");
                        } else {
                            setDepositError("Failed to cancel the deposits or deposits wasn't approved.");
                        }
                    } else {
                        setDepositError("No response received from the server.");
                    }
                })
            },
            onError: function(error, payment) {
                setDepositSuccess(false)
                setDepositApproved(false)
                setPiError(false)
              alert("Error when creating payment.")
              if (payment) {
                alert("Error when creating payment.", payment)
              }
            },
          };

          setClicked(true)
          setTimeout(() => {
            window.Pi.createPayment(paymentData, paymentCallbacks);  
            setClicked(false)
          }, 2000);
    }
    return (
        <>

            {err ? <FlaotingErrorCustom err={err} setErr={setErr} message={depositError} /> : ""}
            <section className={`${styles.DepositSection} `}>
                <div className={styles.DepositArea}>
                    <h1 className="text-4xl p-5">Deposit</h1>
                    <p className='px-5 pb-3 text-info'> ***Deposit only available via PI browser. </p>
                    
                    <p className='px-5 pb-3 text-error'> ***We are currently in beta version do not hold more than 100 PI.</p>
                    
                    <hr />
                    <p className="text-2xl p-5 pb-0">How much credit would you like to deposit?</p>
                    <div className="preDeposit flex flex-wrap">
                        <div onClick={(event) => handleAmount(event, 10)} className={`bg-[#EFF1F5] w-[100px] p-[15px] text-center cursor-pointer m-5 ${amount === 10 ? styles.active : ""}`}>10 PI</div>
                        <div onClick={(event) => handleAmount(event, 20)} className={`bg-[#EFF1F5] w-[100px] p-[15px] text-center cursor-pointer m-5 ${amount === 20 ? styles.active : ""}`}>20  PI</div>
                        <div onClick={(event) => handleAmount(event, 50)} className={`bg-[#EFF1F5] w-[100px] p-[15px] text-center cursor-pointer m-5 ${amount === 50 ? styles.active : ""}`}>50  PI</div>
                        <div onClick={(event) => handleAmount(event, 100)} className={`bg-[#EFF1F5] w-[100px] p-[15px] text-center cursor-pointer m-5 ${amount === 100 ? styles.active : ""}`}>100  PI</div>
                        <div onClick={(event) => handleAmount(event, 250)} className={`bg-[#EFF1F5] w-[100px] p-[15px] text-center cursor-pointer m-5 ${amount === 250 ? styles.active : ""}`}>250  PI</div>
                        <div onClick={(event) => handleAmount(event, 500)} className={`bg-[#EFF1F5] w-[100px] p-[15px] text-center cursor-pointer m-5 ${amount === 500 ? styles.active : ""}`}>500  PI</div>
                    </div>

                    <div className="customDepositArea px-5">
                        <p className="text-2xl pb-5">Enter custom amount</p>
                        <input value={amount} onChange={(e) => setAmount(e.target.value)} placeholder={amount} type="text" className='input input-bordered rounded-none md:w-[80%] w-[100%] bg-[#EFF1F5]' />
                    </div>
                    <DepositModal hidden={depositSuccess} setHidden={setDepositSuccess} data={depositData} />
                    {/* <p className={`text-error px-5 py-0 ${err?"":"hidden"}`}>{depositError}</p> */}
                    <div className="div flex px-5 py-2">
                        <button onClick={handleDeposit} className='btn btn-primary w-[320px]'>
                            {clicked ? <span className="loading loading-dots loading-md"></span> : "Deposit"}
                        </button>
                    </div>
                </div>
                <DepositHistory />
            </section>
        </>
    )
};

const DepositHistory = () => {
    const { logout } = useAuth();
    const [url, setUrl] = useState("/api/transactions/?transaction_direction=IN&limit=8")
    const axiosInstanceJWT = AxiosInstanceJWT()
    const [prevUrl, setPrevUrl] = useState(null)
    const [nextUrl, setNextUrl] = useState(null)

    const [transactions, setTransactions] = useState([])
    const [totalTransactions, setTotalTransactions] = useState(-1)
    const [fetched, setFetched] = useState(false)

    const handlePrevBtn = (event) => {
        if (prevUrl === null) {
            return
        }
        setTransactions([])
        setUrl(prevUrl)

    }
    const handleNextBtn = (event) => {
        if (nextUrl === null) {
            return
        }
        setTransactions([])
        setUrl(nextUrl)
    }



    useEffect(() => {
        setFetched(false)
        const timer = setTimeout(() => {
            const getTransactions = async () => {
                try {
                    const response = await axiosInstanceJWT(url)
                    return response
                } catch (error) {
                    throw error
                }
            }
            const data = getTransactions()
            data.then(data => {
                if (data.status === 200) {
                    setTotalTransactions(data.data.count)
                    setTransactions(data.data.results);
                    setPrevUrl(data.data.previous)
                    setNextUrl(data.data.next)
                    setFetched(true)
                }
            }).catch(err => {
                if (err.response) {
                    if (err.response.status === 401) {
                        logout();
                    } else {
                        alert("Unexpected error with status code: ", err.response.status);
                    }
                } else {
                    alert("No response received from the server.");
                }
            })
        }, 2000);
        return (() => clearTimeout(timer))
    }, [url])

    return (
        <div className={styles.DepositHistory}>
            <div className={styles.DepositHistoryArea}>
                <ul className={`${styles.DepositsList}`}>

                    <li className={`${styles.Deposits} p-5 pb-0`}>
                        <div className="text-xl w-[120px] min-w-[120px]">Date</div>
                        <div className="text-xl w-[150px] min-w-[150px]">Transaction ID</div>
                        <div className="text-xl w-[180px] min-w-[180px]">Transaction Status</div>
                        <div className="text-xl w-[120px] min-w-[120px]">Amount</div>
                        <div className="text-xl w-[500px]">TX ID</div>
                    </li>

                    {fetched ? (
                        transactions.length > 0 ?
                            transactions.map(transaction => {
                                return <Transaction
                                    key={transaction.id}
                                    date={transaction.created_at}
                                    id={transaction.id}
                                    status={transaction.status}
                                    amount={transaction.amount}
                                    
                                    txid = {transaction.TX_ID}
                                />
                            }) : <EmptyMessage message={"No transactions found."} />
                    ) : <LoadingArea />}
                </ul>

                <div className={`DepositHistoryBtn flex justify-center p-5 ${totalTransactions > 8 ? "" : "hidden"}`}>
                    <button onClick={handlePrevBtn} className={`btn btn-primary w-[150px] ${prevUrl === null ? "pointer-events-none" : ""}`}>Previous</button>
                    <button onClick={handleNextBtn} className={`btn btn-primary w-[150px] ml-5 ${nextUrl === null ? "pointer-events-none" : ""}`}>Next</button>
                </div>
            </div>

        </div>
    )
}


export default memo(Deposit);


const Transaction = (props) => {
    const pending = <div className='bg-warning text-sm text-white w-[100px] rounded text-center'> Pending </div>
    const complete = <div className='bg-success text-sm text-white w-[100px] rounded text-center'> Complete </div>
    const failed = <div className='bg-error text-sm text-white w-[100px] rounded text-center'> Failed </div>

    let status = pending

    if (props.status === "F") {
        status = failed
    } else if (props.status === "C") {
        status = complete
    }

    return (
        <>
            <li className={`${styles.Deposits} p-5 pt-0 pb-0 font-light text-xl`}>
                <div className="w-[120px] min-w-[120px]">{convertDatetimeToDate(props.date)}</div>
                <div className="w-[150px] min-w-[150px]">{props.id}</div>
                <div className="w-[180px] min-w-[180px]">
                    {status}
                </div>
                <div className="w-[120px] min-w-[120px]">{(props.amount).toFixed(2)} PI</div>
                <div className="w-[500px]">{props.txid}</div>
            </li>

        </>
    )
}


const DepositModal = (props) => {

    const handleHideBtn = () => {
        props.setHidden(false)
    }
    return (
        <>
            <div className={`${styles.blurryBackgroundSection} ${styles.blurryBackground} ${props.hidden ? '' : "hidden"}`}>
                <div className={styles.ModalArea}>
                    <button onClick={handleHideBtn} className={styles.closeModal}>
                        <img src="/dashboardassets/delete.png" alt="" />
                    </button>
                    <div className='text-center text-2xl font-bold flex items-center justify-center pt-[50px]'>
                        <img className='m-1' style={{width:"25px"}} src="/dashboardassets/success.png" alt="" /> 
                        <span>Deposit successfully completed.</span> 
                    </div>
                    <br />
                    <div className='text-center text-sm font-light p-2 text-primary'>
                        Amount = {props.data.amount} PI
                    </div>
                    <br />
                    {/* <p className='text-sm'></p> */}
                </div>
            </div>
        </>
    )
}