import { PageLocation } from "./GlobalTemplates/PageLocation";
import RegisterArea from "./register/RegisterForm";
import Subscribe from "./home/Subscribe";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { useEffect } from "react";
const Register = ()=>{
    const { isLogged, login, logout } = useAuth();
    const navigate = useNavigate()

    useEffect(()=>{
        window.onload = function() {
            if(isLogged){
                navigate('/dashboard')
            }
        };
    },[])
    return (
        <>
            <PageLocation head={"Register"} tail={"Home / register"}/>
            <RegisterArea />
            <Subscribe />
        </>
    )

}

export default Register