import { memo, useState } from "react"
import { useAuth } from './AuthContext';
import { Outlet, Link } from "react-router-dom";
import './Nav.css';

const NavWithoutLogin = () => {
    const [navActive, setnavActive] = useState(false)
    const handleNav = () => {
        if (navActive) {
            setnavActive(false)
        } else {
            setnavActive(true)
        }
    }

    return (
        <>
            <div className="navWrapper">
                <div className={`navbar bg-base-100 pb-5`}>

                    <div className="navbar-start">
                        <a className="btn btn-ghost normal-case text-4xl">ESCROWFY</a>
                    </div>
                    <div className="navbar-end">
                        <div className="dropdown drop-down-nav-responsive">
                            <label tabIndex={0} className="btn btn-ghost btn-circle" onClick={handleNav}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                            </label>
                        </div>

                        <ul className={`drawer ${navActive ? 'active' : ''} z-50`}>
                            <li>
                                <Link onClick={handleNav} to="/">Home</Link>
                            </li>
                            <li>
                                <Link onClick={handleNav} to="/products">All Products</Link>
                            </li>
                            <li>
                                <Link onClick={handleNav} to="/login">Login/Register</Link>
                            </li>
                            <li>
                                <Link onClick={handleNav} to="/contact">Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mainNavWrapper">
                <ul className="flex default-nav mainNav text-xl">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/products">All Products</Link>
                    </li>
                    <li>
                        <Link to="/login">Login/Register</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact Us</Link>
                    </li>
                </ul>
            </div>
            <div className="outlet">
                <Outlet />
            </div>

            <footer className="footer p-10 bg-base-300 text-base-content">
                <nav>
                    <header className="footer-title">Services</header>
                    {/* <a className="link link-hover">Branding</a>
                    <a className="link link-hover">Design</a>
                    <a className="link link-hover">Marketing</a>
                    <a className="link link-hover">Advertisement</a> */}
                </nav>
                <nav>
                    <header className="footer-title">Company</header>
                    <Link to={'/about'} className="link link-hover">About us</Link>
                    <Link to={'/contact'} className="link link-hover">Contact</Link>
                </nav>
                <nav>
                    <header className="footer-title">Social</header>
                    <div className="grid grid-flow-col gap-4">
                        <a target="_blank" href="https://t.me/+wj97ze6at6RiNWI9"><img className="w-[25px]" src="/telegram.png" alt="" /></a>
                    </div>
                </nav>
            </footer>
        </>
    )
}

const NavWithLogin = () => {
    const [navActive, setnavActive] = useState(false)
    const { isLogged, login, logout } = useAuth();
    
    const handleNav = () => {
        if (navActive) {
            setnavActive(false)
        } else {
            setnavActive(true)
        }
    }
    const handleLogout = () => {
        logout()
        if (navActive) {
            setnavActive(false)
        } else {
            setnavActive(true)
        }
    }
    return (
        <>
            <div className="navWrapper">
                <div className={`navbar bg-base-100 pb-5`}>

                    <div className="navbar-start">
                        <a className="btn btn-ghost normal-case text-4xl">ESCROWFY</a>
                    </div>
                    <div className="navbar-end">
                        {/* <ul className="flex default-nav text-xl">
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/login">Login</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul> */}
                        <div className="dropdown drop-down-nav-responsive">
                            <label tabIndex={0} className="btn btn-ghost btn-circle" onClick={handleNav}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                            </label>
                        </div>

                        <ul className={`drawer ${navActive ? 'active' : ''} z-50`}>
                            <li>
                                <Link onClick={handleNav} to="/">Home</Link>
                            </li>
                            <li>
                                <Link onClick={handleNav} to="/products">All Products</Link>
                            </li>
                            <li>
                                <Link onClick={handleNav} to="/dashboard">Dashboard</Link>
                            </li>
                            <li>
                                <Link onClick={handleNav} to="/messages">Messages</Link>
                            </li>
                            <li>
                                <Link onClick={handleNav} to="/contact">Contact Us</Link>
                            </li>

                            <li>
                                <Link onClick={handleLogout}>Logout</Link>
                            </li>

                        </ul>

                    </div>

                                        {/* 
                    <div className="navbar-end">
                        <div className="dropdown dropdown-end">
                            <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                                <div className="w-10 rounded-full">
                                    <img src={profile} alt="profile" />
                                </div>
                            </label>
                            <ul tabIndex={0} className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
                                <li>
                                    <a className="justify-between">
                                        Profile
                                        <span className="badge">New</span> 
                                    </a>
                                </li>
                                <li><a>Settings</a></li>
                                <li><a>Logout</a></li>
                            </ul>
                        </div>
                    </div> */}

                </div>
            </div>
            <div className="mainNavWrapper">
                <ul className="flex default-nav mainNav text-xl">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/products">All Products</Link>
                    </li>

                    <li>
                        <Link to="/dashboard">Dashboard</Link>
                    </li>
                    
                    <li>
                        <Link to="/messages">Messages</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                        <Link onClick={handleLogout}>Logout</Link>
                    </li>
                </ul>
            </div>
            <div className="outlet">
                <Outlet />
            </div>

            <footer className="footer p-10 bg-base-300 text-base-content">
                <nav>
                    <header className="footer-title">Services</header>
                    {/* <a className="link link-hover">Branding</a>
                    <a className="link link-hover">Design</a>
                    <a className="link link-hover">Marketing</a>
                    <a className="link link-hover">Advertisement</a> */}
                </nav>
                <nav>
                    <header className="footer-title">Company</header>
                    <Link to={'/about'} className="link link-hover">About us</Link>
                    <Link to={'/contact'} className="link link-hover">Contact</Link>
                </nav>
                <nav>
                    <header className="footer-title">Social</header>
                    <div className="grid grid-flow-col gap-4">
                        <a target="_blank" href="https://t.me/+wj97ze6at6RiNWI9"><img className="w-[25px]" src="/telegram.png" alt="" /></a>
                    </div>
                </nav>
            </footer>
        </>
    )
}

const Navigation = () => {
    const { isLogged, login, logout } = useAuth();
    // console.log(isLogged);
    if (isLogged) {
        return <NavWithLogin />
    } else {
        return <NavWithoutLogin />
    }
}

export default memo(Navigation)