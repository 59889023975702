import BuyNowItem from "./buynow/BuyNowItem"
import Subscribe from "./home/Subscribe";


const BuyNow = ()=>{
    return (
        <>
            <BuyNowItem />
            <Subscribe />
        </>
    )
}

export default BuyNow